<template>
  <div>
    <div class="auth-wrapper auth-wrapper-login auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <b-img
              :src="logoUrl"
              alt="Payrite"
              style="width:150px"
            />
          </b-link>
          <b-card-text class="mb-2 text-center">
            Log in met je e-mailadres en wachtwoord
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-3"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label-for="email"
                label="E-mailadres"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Wachtwoord</label>
                  <b-link :to="{name:'auth-forgot-password'}" tabindex="-1">
                    <small>wachtwoord vergeten?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <transition name="fade">
                <b-form-group
                  v-show="showTfa"
                  v-if="showTfa"
                  label="Two-Factor Authenticatie Code"
                  label-for="tfa"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tfa"
                    vid="tfa"
                    rules="required|integer|digits:6"
                  >
                    <b-form-input
                      id="tfa"
                      v-model="tfa"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      name="tfa"
                      placeholder="123 456"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </transition>
              <b-alert
                :show="showLoginError"
                v-height-fade.appear
                variant="danger"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  Vul een valide gebruikersnaam, wachtwoord en eventuele TFA code in.
                </div>
              </b-alert>
              <b-button
                class="mt-2"
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Inloggen
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <span>Nieuw op ons platform? </span>
            <br/>
            <b-link :to="{name:'auth-register'}">
              Maak een account aan
            </b-link>
          </b-card-text>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
    <template>
      <p class="clearfix mb-0 login-footer">
      </p>
    </template>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BImg, BAlert, VBTooltip,
} from 'bootstrap-vue'
import {
  required,
  email,
  integer,
  digits,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    // BSV
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      password: '',
      userEmail: '',
      status: '',
      // validation rules
      required,
      email,
      integer,
      digits,
      showLoginError: false,
      showTfa: false,
      tfa: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      return this.logoImg
    },
  },
  methods: {
    login() {
      this.showLoginError = false
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.userEmail,
            password: this.password,
            tfa: this.tfa,
          })
            .then(response => {
              if (response.data.tfaRequired) {
                this.showTfa = true
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Two-Factor Authentication vereist',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: 'Vul uw Two-Factor Authentication code uit de app in om verder te gaan',
                  },
                })
              } else {
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)

                const { userData } = response.data
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)

                this.$router.replace(getHomeRouteForLoggedInUser(userData.type))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${userData.fullName || userData.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'U bent succesvol ingelogd!',
                      },
                    })
                  })
              }
            })
            .catch(() => {
              this.showLoginError = true
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper-login{
  background: url("../../../assets/images/pages/inlog.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
